.container-full-width{
    padding: 0 40px;
    max-width: 2000px !important;
    margin: auto;
}
.section{
    padding: 90px 0;
    @media(max-width: 768px){
        padding: 45px 0;
    }
}
.border{
    border: 1px solid #000;
}
body .border-top-bottom{
    border-top: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
}
body .border-top{
    border-top: 1px solid #000 !important;
}
body .border-bottom{
    border-bottom: 1px solid #000 !important;
}
.projects-items-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 50px;
    @media(max-width: 1024px){
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;

    }
    @media(max-width: 400px){
        grid-template-columns: 1fr;
        grid-gap: 10px;


    }
    .item{
        position: relative;
        text-decoration: none;
        width: 100%;
        .description-box{
            position: relative;
            bottom: 0;
            // background: rgba($color: #000, $alpha: 0.8);
            // background: linear-gradient(rgba(#000, 0), rgba(#000, 0.5),   rgba(#000, 0.9), rgba(#000, 1));
            width: 100%;
            color: #000;
            font-weight: 400;
            padding: 15px 0 ;
            // border-top: 1px solid #ffffff7d;
            .title{
                font-size: 18px;
                color: #000;
                font-weight: 500;
                text-transform: uppercase;
              
            }
            .sub-title{
                color: #000;
                color: #000;
                font-size: 14px;
                font-weight: 300;
            }
            .type{
                text-transform: uppercase;
                font-size: 12px;
                letter-spacing: 1px;
            }
        }
        &:hover{
            .description-box{
                // background: rgba($color: #fff, $alpha: 0.1);
                color: #000000;

            }
            &:before{
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                position: absolute;
                content: '';
                // background: linear-gradient(rgba(#000, 0.1),  rgba(#fff, 0.7), rgba(#fff, 1));
            }
            .image-wrapper img{
                scale: 1.1;
                transition: all 0.3s;
            }
           
        }
        .image-wrapper{
            border: 1px solid #000;
            overflow: hidden;
            aspect-ratio: 1/1;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.3s;            
            }

        }
        
    }
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Raleway", sans-serif;

}
img{
    max-width: 100%;
}
a{
    text-decoration: none;
    
}
p{
    font-size: 18px;
}

.section-title{
    margin-bottom: 40px;
    text-transform: uppercase;
    color: #000;
    font-family: 'Lato', sans-serif;
}

span.section-small-heading {
    font-size: 13px;
    color: #000;
    margin-bottom: 18px;
    display: block;
    font-family: 'Lato', sans-serif;
    letter-spacing: 1px;
    i{
        margin-right: 5px;
    }
}